<template>
  <el-tabs v-model="labelVal" type="border-card">
    <el-tab-pane label="单兵">
      <individual v-if="labelVal === '0'"></individual>
    </el-tab-pane>
    <el-tab-pane label="摄像头">
      <camera v-if="labelVal === '1'"></camera>
    </el-tab-pane>
    <el-tab-pane label="手环">
      <wristband v-if="labelVal === '2'"></wristband>
    </el-tab-pane>
    <el-tab-pane label="气体">
      <gas v-if="labelVal === '3'"></gas>
    </el-tab-pane>
</el-tabs>
</template>

<script>
import individual from './components/individual' // 单兵
import camera from './components/camera' // 摄像头
import wristband from './components/wristband' // 手环
import gas from './components/gas' // 气体
export default {
  name: 'DeviceDeviceConfig',
  components: {
    individual,
    camera,
    wristband,
    gas,
  },
  data () {
    return {
      labelVal: 0,
    }
  },
}
</script>

<style lang="stylus" scoped>

</style>
