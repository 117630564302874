<template>
  <el-dialog
   :close-on-click-modal='false'
   :close-on-press-escape='false'
   :destroy-on-close='true'
   :show-close='false'
    width="45%" :title="`${isEdit ? '编辑' : '添加'}配置`" :visible.sync="isShow">
    <div class="all-input">
      <div class="item-input">
        <div class="label"><span class="red">*</span>账号名称：</div>
        <el-input placeholder="请输入账号名称" v-model="formData.name"  class="mini"></el-input>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>App_Key：</div>
        <el-input placeholder="请输入App_Key" v-model="formData.app_key"  class="mini"></el-input>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>Secret：</div>
        <el-input placeholder="请输入Secret" v-model="formData.secret"  class="mini"></el-input>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>Access_Token：</div>
        <el-input placeholder="请输入Access_Token" v-model="formData.accessToken"  class="mini"></el-input>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addCamera, updCamera } from '@/api/device'
export default {
  name: 'addCamera',
  props: {
    loadChange: Function,
  },
  data() {
    return {
      isShow: false, // 是否打开弹窗
      formData: {
        name: '',
        app_key: '',
        secret: '',
        accessToken: '',
      },
      toastData: {
        name: '请输入账号名称',
        app_key: '请输入APP KEY',
        secret: '请输入 SECRET',
        accessToken: '请输入 ACCESS TOKEN',
      },
      isEdit: false,
    }
  },
  methods: {
    open: function (data) {
      this.isShow = true
      this.isEdit = false
      if (data) {
        this.isEdit = true
        const { id, name, app_key: key, secret, accessToken } = data
        this.formData = { id, name, app_key: key, secret, accessToken }
      }
    },
    close: function () {
      this.isShow = false
      this.clearData()
    },
    submit: function() {
      for (const k in this.toastData) {
        if (!this.formData[k]) {
          this.$toast(this.toastData[k])
          return false
        }
      }

      if (this.isEdit) this.edit()
      else this.save()
    },
    save: function() {
      addCamera(this.formData).then(res => {
        console.log(res, '::::这是探究')
        this.$toast(res.message)
        if (res.status === 200) {
          this.close()
          this.loadChange && this.loadChange()
        }
      })
    },
    edit: function() {
      updCamera(this.formData).then(res => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.close()
          this.loadChange && this.loadChange()
        }
      })
    },
    clearData: function() {
      for (const k in this.formData) {
        this.formData[k] = ''
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.all-input {
  .label{
    width 119px;
  }
}
</style>
