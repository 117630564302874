<template>
  <div>
    <div class="all-input">
      <div v-for="(item, index) in dataArr" :key="index" :style="!index && 'margin-top: 40px;'" class="item-input">
        <div :title="item.info" class="label">{{ item.info }}：</div>
        <el-input :ref='item.menu_name' placeholder="请输入" v-model="item.value"  class="big"></el-input>
      </div>
    </div>
    <el-button @click="submit" style="margin-left: 150px; margin-bottom: 40px;" type="primary">保存修改</el-button>
  </div>
</template>

<script>
import { getIndConfig, updInWBConfig } from '@/api/device'
export default {
  name: 'individual',
  data () {
    return {
      dataArr: [],
    }
  },
  methods: {
    submit: function() {
      const data = {}
      this.dataArr.forEach(s => {
        data[s.menu_name] = this.$refs[s.menu_name][0].$options.propsData.value
      })

      updInWBConfig(data).then(res => {
        this.$toast(res.message)
      })
    },
  },
  created() {
    getIndConfig().then(res => {
      const { result } = res
      const { data } = result
      this.dataArr = data
    })
  },
}
</script>

<style lang="stylus" scoped>
.all-input{
  display block;
  .label {
    width 10%;
    text-overflow ellipsis;
    white-space nowrap;
    overflow hidden;
  }
  .item-input {
    justify-content flex-start;
    height 38px;
    margin-bottom 20px;
  }
}
</style>
