<template>
  <div>
    <TABS :tabChange='onCallbackTab' :list="tabsArr"></TABS>
    <TABLE :buttonChange='onCallbackButton' :isShowSele="false" :list="tableArr" :data="dataArr"></TABLE>

    <add-camera :loadChange='_loadData' ref="addC"></add-camera>
  </div>
</template>

<script>
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import addCamera from './addCamera'
import { getCamera, delCamera, getCameraDetail } from '@/api/device'
export default {
  name: 'camera',
  components: {
    TABS,
    TABLE,
    addCamera,
  },
  data() {
    return {
      tabsArr: [
        {
          type: 'primary',
          name: '添加配置',
          func: 'openAddCamera',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'name',
          label: '账号名称',
          width: '270',
        },
        {
          prop: 'app_key',
          label: 'App_key',
          width: '270',
        },
        {
          prop: 'secret',
          label: 'secret',
          width: '270',
        },
        {
          prop: 'accessToken',
          label: 'accessToken',
          width: '500',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: 'editCamera',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeCamera',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
      },
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      getCamera(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
          item.buttons = JSON.parse(JSON.stringify(this.butnArr))
        })
        this.dataArr = result.data
      })
    },

    openAddCamera: function() {
      this.$refs.addC.open()
    },

    editCamera: function({ id }) {
      getCameraDetail(id).then(res => {
        this.$refs.addC.open(res.result.data)
      })
    },

    // 删除摄像头
    removeCamera: function({ id }) {
      delCamera(id).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 收到 table内方法 回调
    onCallbackButton: function(item, func) {
      this[func](item)
    },
    // 收到 tabs 回调
    onCallbackTab: function({ item, index }) {
      this[item.func]()
    },

  },
}
</script>

<style lang="stylus" scoped></style>
